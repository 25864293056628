<template>
  <div v-if="isMobile" class="phoneCompanyBox home">
    <div class="divBox">
      <div class="navigationBox">
        <ul>
          <li v-for="(item,index) in dataList" :key="item.name" :class="liActive==index?'liActive':''"
              @click="toLiData(item,index)" style="cursor: pointer">{{item.name}}
          </li>
        </ul>
      </div>
      <!--      公司资质-->
      <transition name="moveS" appear>
        <div class="first" v-if='liActive==0' key="box1">
          <img style="width: 90%;margin-left: 5%" :src="constUrl+dataList[0].itemList[0].picture"/>
          <div class="introduceText">
            <p> {{dataList[0].itemList[1].detail}}</p>
            <p v-for="item in dataList[0].itemList[1].itemList" :key="item.id">
              {{item.detail}}</p>
          </div>

          <div class="block">
            <img src="../views/assets/timeLiBg.png"/>
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in dataList[0].itemList[2].itemList"
                :key="index"
                color="#0d9efa"
                size="large"
                :timestamp="activity.title">
                {{activity.name}}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <div class="qualification" v-else-if='liActive==1' key="box2">
          <img style="width: 100%" :src="constUrl+dataList[1].itemList[0].picture"
               :key="constUrl+dataList[1].itemList[0].picture"/>
        </div>
      </transition>
      <footer-box  class="footerBoxAll" style="z-index: 999" ></footer-box>
    </div>

  </div>
  <div v-else class="home">
    <div class="divBox">
      <div class="navigationBox">
        <ul>
          <li v-for="(item,index) in dataList" :key="item.name" :class="liActive==index?'liActive':''"
              @click="toLiData(item,index)" style="cursor: pointer">{{item.name}}
          </li>
        </ul>
      </div>
      <!--      公司资质-->
      <transition name="moveS" appear>
        <div class="first" v-if='liActive==0' key="box1">
          <el-row>
            <el-col :span="7" style="margin-right:1% ">
              <img style="width: 100%" :src="constUrl+dataList[0].itemList[0].picture"/>
            </el-col>
            <el-col :span="8" style="margin-right:1% ">
              <div class="introduceText">
                <p> {{dataList[0].itemList[1].detail}}</p>
                <p v-for="item in dataList[0].itemList[1].itemList" :key="item.id">
                  {{item.detail}}</p>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="block">
                <img src="../views/assets/timeLiBg.png"/>
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in dataList[0].itemList[2].itemList"
                    :key="index"
                    color="#0d9efa"
                    size="large"
                    :timestamp="activity.title">
                    {{activity.name}}
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="qualification" v-else-if='liActive==1' key="box2">
          <img style="width: 100%" :src="constUrl+dataList[1].itemList[0].picture"
               :key="constUrl+dataList[1].itemList[0].picture"/>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
  import ItemMixins from "./item.mixins";

  export default {
    mixins: [ItemMixins],
    data() {
      return {
        dataList: [{itemList: [{picture: []}, {detail: ''}, {itemList: []}]}],
        liActive: 0,
      }
    },
    created() {
      if (_isMobile()) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    mounted() {
      if (this.$route.query.forms) {
        this.chilidList()
        return
      }
      // 进行判断是否是第一次进入页面，如果是第一次进入页面就调取接口请求，同时并生成sessionStorage,当前页面不是第一次进入
      this.init()

    },
    methods: {
      //二级导航栏
      toLiData(item, index) {
        this.liActive = index
      },
      //解决从首页跳转过来的问题
      async chilidList() {
        let res = await this.getHeadMenuList()
        this.dataList =  await res[1].itemList
      },
      //获取数据
      async init() {
        await this.getHeadMenuList()
        this.dataList = await this.getChildList()
      },
    },
  }
</script>
<style scoped lang="less">
  .home {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    position: relative;
    .footerBoxAll{
      position: absolute !important;
      bottom: 0;

    }
    .navigationBox {
      position: absolute;
      right: 50vw;
      top: 12vh;
      width: 400px;
      height: 50px;
      transform: translateX(200px);
      /*z-index: 9999;*/

      ul {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        li {
          cursor: pointer;
          transition: font-size 0.3s ease-in-out;
          width: 100%;
          height: 80px;
          font-size: 20px;
          /*font-weight: 600;*/
          color: white;
          position: relative;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
        }

        li:after {
          content: '';
          width: 10px;
          height: 10px;
          border: 3px solid white;
          background: transparent;
          border-radius: 50%;
          position: absolute;
          right: -30px;
          margin-top: 4px;
          /*top: 8px;*/
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
          /*position: absolute;*/
        }

        .liActive {
          width: 100%;
          height: 80px;
          font-size: 25px;
          font-weight: 600;
          position: relative;
          color: #0d9efa;
          transform: scale(1.1);
          transition: all 0.4s;
          /**/
          text-shadow: 0px 0px 2px rgba(13, 158, 250, 1);
        }

        .liActive:after {
          content: '';
          width: 15px;
          height: 15px;
          border: 3px solid #0d9efa;
          background: transparent;
          border-radius: 50%;
          position: absolute;
          right: -22px;
          margin-top: 4px;
          /*top: 8px;*/
          box-shadow: 0px 0px 4px rgba(13, 158, 250, 1);
          /*position: absolute;*/
        }

      }

    }
    /*公司简历*/
    .first {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 18vh;
      width: 100vw;
      height: 80vh;

      .el-row {
        width: 80%;
        /*margin-left: 10%;*/
        background: white;
        height: 80%;
        margin: auto;
        margin-top: 5%;

        .el-col {
          height: 100%;

          .introduceText {
            text-align: left;
            box-sizing: border-box;
            padding-left: 5%;

            p {
              line-height: 40px;
              font-size: 16px;
              color: black;
              font-weight: 200;
              text-indent: 20px;
              color: #25282B;
            }
          }

          .block {
            position: relative;
            width: 80%;
            height: 80%;
            box-sizing: border-box;
            padding-left: 50px;

            background-size: 100%;


            img {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0%;
              left: 0;

            }
          }

        }
      }
    }
    /*  公司资质*/
    .qualification {
      position: absolute;
      left: 0;
      top: 18vh;
      width: 90vw;
      height: 80vh;
      box-sizing: border-box;

      margin-left: 5vw;
      overflow: auto;

      /deep/ .el-carousel__item {
        background: transparent;
        text-align: center;

        img {
          height: 100%;
        }
      }

      .imgBox {
        padding: 5% 5%;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background: white;
        /*overflowY: auto;*/
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        overflow: auto;

        div:hover {
          transform: scale(1.2);
        }

        div {
          width: 19%;
          height: 40%;
          transition: transform 0.5s;

          img {
            width: 80%;
            height: 80%;
            margin: 0px 10%;
          }

          p {
            color: #25282b;
            margin: 0px 10%;
          }
        }
      }
    }
  }


</style>
<style lang="less" scoped>
  .phoneCompanyBox {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    position: relative;
    .navigationBox {
      position: absolute;
      top: 12vh;
      right: 70%;
      width: 80vw;
      height: 20px;
      transform: translateX(200px);
      /*z-index: 9999;*/

      ul {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        li {
          cursor: pointer;
          transition: font-size 0.3s ease-in-out;
          width: 100%;
          height: 80px;
          font-size: 20px;
          /*font-weight: 600;*/
          color: white;
          position: relative;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
        }

        li:after {
          content: '';
          width: 10px;
          height: 10px;
          border: 3px solid white;
          background: transparent;
          border-radius: 50%;
          position: absolute;
          right: -30px;
          margin-top: 4px;
          /*top: 8px;*/
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
          /*position: absolute;*/
        }

        .liActive {
          width: 100%;
          height: 80px;
          font-size: 25px;
          font-weight: 600;
          position: relative;
          color: #0d9efa;
          transform: scale(1.1);
          transition: all 0.4s;
          /**/
          text-shadow: 0px 0px 2px rgba(13, 158, 250, 1);
        }

        .liActive:after {
          content: '';
          width: 15px;
          height: 15px;
          border: 3px solid #0d9efa;
          background: transparent;
          border-radius: 50%;
          position: absolute;
          right: -22px;
          margin-top: 4px;
          /*top: 8px;*/
          box-shadow: 0px 0px 4px rgba(13, 158, 250, 1);
          /*position: absolute;*/
        }

      }

    }
    /*公司简历*/
    .first {
      width: 100vw;
      height: 100vh;
      overflow: auto;
      position: absolute;
      left: 0;
      top: 18vh;
      width: 100vw;
      height: 80vh;
      .introduceText {
        text-align: left;
        box-sizing: border-box;
        padding-left: 5%;

        p {
          line-height: 40px;
          font-size: 16px;
          color: black;
          font-weight: 200;
          text-indent: 20px;
          color: #25282B;
        }
      }

      .block {
        position: relative;
        width: 60%;
        height: 60%;
        box-sizing: border-box;
        padding-left: 20%;
        padding-top: 10vw;
        background-size: 100%;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0%;
          left: 30%;

        }
      }
    }
    /*  公司资质*/
    .qualification {
      position: absolute;
      left: 0;
      top: 18vh;
      width: 90vw;
      height: 80vh;
      box-sizing: border-box;

      margin-left: 5vw;
      overflow: auto;

      /deep/ .el-carousel__item {
        background: transparent;
        text-align: center;

        img {
          height: 100%;
        }
      }

      .imgBox {
        padding: 5% 5%;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background: white;
        /*overflowY: auto;*/
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        overflow: auto;

        div:hover {
          transform: scale(1.2);
        }

        div {
          width: 19%;
          height: 40%;
          transition: transform 0.5s;

          img {
            width: 80%;
            height: 80%;
            margin: 0px 10%;
          }

          p {
            color: #25282b;
            margin: 0px 10%;
          }
        }
      }
    }
    footerBox{

    }
  }

</style>

